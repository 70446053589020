import {KlevuConfig} from '@klevu/core';
import Axios from 'axios';

export const credentials = {
  url: 'https://eucs30v2.ksearchnet.com/cs/v2/search',
  apiKey: 'klevu-166323096484715624',
};

KlevuConfig.init({
  ...credentials,
  axios: Axios,
});
