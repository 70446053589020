const CreateGlobalECookie = () => {
  //  if isBrowser check that window is defined
  const createReplacementCookie = function (cookieDomain) {
    function getParameterByName(name, url = window.location.href) {
      name = name.replace(/[\[\]]/g, '\\$&');
      const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }
    function ticksToDate(ticks) {
      return Number.isInteger(ticks)
        ? new Date(ticks / 1e4 + new Date('0001-01-01T00:00:00Z').getTime())
        : null;
    }
    //* If URL contains the query parameter replacementExpire, create cookie GE_Replacement
    let replacementExpireParam = parseInt(
      getParameterByName('replacementExpire'),
    );
    if (
      replacementExpireParam !== null &&
      replacementExpireParam > Date.now()
    ) {
      const cookieStringifiedValue = encodeURIComponent(
        JSON.stringify({
          glCountry: getParameterByName('glCountry'),
          glCurrency: getParameterByName('glCurrency'),
        }),
      );
      const expirationInUTC = ticksToDate(replacementExpireParam).toUTCString();
      document.cookie = `GE_Replacement=${cookieStringifiedValue}; expires=${expirationInUTC}; path=/; domain=${cookieDomain}`;
    }
  };

  const cookieDomain = 'lavenhamjackets.com';
  //can require custom value on stores where checkout page domain is different
  createReplacementCookie(cookieDomain);
};

CreateGlobalECookie();
