import {isBrowser} from '@shopify/hydrogen';
export const CreateAccessibeEmbed = () => {
  //  if isBrowser check that window is defined

  const initAB = () => {
    let s = document.createElement('script');
    let h = document.querySelector('head') || document.body;
    s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
    s.async = true;
    s.onload = function () {
      if (acsbJS) {
        acsbJS.init({
          statementLink: '',
          footerHtml: '',
          hideTrigger: false,
          disableBgProcess: false,
          language: 'en',
          position: 'left',
          leadColor: '#000000',
          triggerColor: '#000000',
          triggerRadius: '50%',
          triggerPositionX: 'left',
          triggerPositionY: 'bottom',
          triggerIcon: 'people',
          triggerSize: 'medium',
          triggerOffsetX: 20,
          triggerOffsetY: 20,
          mobile: {
            triggerSize: 'small',
            hideTrigger: false,
            triggerPositionX: 'right',
            triggerPositionY: 'bottom',
            triggerOffsetX: 10,
            triggerOffsetY: 10,
            triggerRadius: '50%',
          },
        });
      }
    };
    h.appendChild(s);
  };
  initAB();
};

CreateAccessibeEmbed();
