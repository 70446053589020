export const CreateLegacy = () => {
  const id = import.meta.env.VITE_GTM_LEGACY_ID;
  window.dataLayer = window.dataLayer || [];
  function gtag() {
    dataLayer.push(arguments);
  }
  const initOmit = () => {
    let sc = document.createElement('script');
    setTimeout(function () {
      const url = 'https://www.googletagmanager.com/gtag/js?id=' + id + '';
      sc.src = url;
      sc.setAttribute('async', 'true');
      document.getElementsByTagName('head')[0].appendChild(sc);
    }, 10);
  };
  initOmit();

  gtag('config', id);
};

CreateLegacy();
