export const CreateOmetriaEmbed = () => {
  // get env variables in here from vite.
  const ometriaTrackingId = import.meta.env.VITE_OMETRIA_TRACKING_ID;
  const initOmt = () => {
    let sc = document.createElement('script');
    setTimeout(function () {
      const url = `//cdn.ometria.com/tags/${ometriaTrackingId}.js`;
      sc.src = url;
      sc.setAttribute('async', 'true');
      document.getElementsByTagName('head')[0].appendChild(sc);
    }, 15);
  };
  initOmt();
};

CreateOmetriaEmbed();
